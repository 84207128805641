<template>
  <div id="contact" class="d-flex justify-content-center flex-wrap my-5">
    <b-toast
      id="contact-success"
      title="Nachricht verschickt"
      variant="success"
      toaster="b-toaster-top-center"
      solid
      auto-hide-delay="10000"
    >
      <p class="font-weight-bold">Vielen Dank für die Kontaktaufnahme!</p>
      <p>
        Ihre Nachricht wurde erfolgreich verschickt. Wir werden Ihnen
        schnellstmöglich antworten.
      </p>
    </b-toast>
    <b-toast
      id="contact-error"
      title="Nachricht nicht verschickt"
      variant="danger"
      toaster="b-toaster-top-center"
      solid
      no-auto-hide
    >
      <p>Oooops... Entschuldigung hier ist etwas schief gelaufen.</p>
      <p>Kontaktieren Sie uns bitte per E-Mail unter kontakt@awh-beratung.de</p>
    </b-toast>
    <div class="d-flex contact-info-area flex-wrap">
      <div class="contact-img align-self-end order-1 order-lg-0 mt-lg-4">
        <b-img
          :src="contact.image || '/assets/contact/contact-all-min.png'"
          fluid
          alt="Kontakt Personen"
        ></b-img>
      </div>
      <div class="contact-info-area py-5 mr-4 ml-3 ml-lg-1 order-0 order-lg-1">
        <div class="contact-data">
          <h2 class="mb-4">
            <b-icon-chat class="mr-2"></b-icon-chat>
            <span v-if="contact.pos === 'them'">Ihre Ansprechpartner</span>
            <span v-if="contact.pos === 'him'">Ihr Ansprechpartner</span>
            <span v-if="contact.pos === 'her'">Ihre Ansprechpartnerin</span>
          </h2>
          <div class="contact-names mb-2 font-weight-bold">
            <div>{{ contact.name }}</div>
            <div v-if="contact.name2">{{ contact.name2 }}</div>
          </div>
          <div>{{ contact.phone }}</div>
          <div>{{ contact.mail }}</div>
        </div>
      </div>
    </div>
    <div class="contact-form-area pt-5 pb-3 px-3">
      <div class="contact-form">
        <div class="full-height">
          <h2 class="mb-4">
            <b-icon-envelope></b-icon-envelope>
            Ihr direkter Kontakt
          </h2>
          <b-form @submit="validate" id="contact-form">
            <div
              class="d-flex flex-column justify-content-between"
              style="height: 100%"
            >
              <b-row>
                <b-col style="padding-right: 2px">
                  <b-form-radio-group
                    id="gender-group"
                    v-model="form.gender"
                    name="gender-selection"
                  >
                    <b-form-radio value="mr">Herr</b-form-radio>
                    <b-form-radio value="mrs">Frau</b-form-radio>
                  </b-form-radio-group>
                </b-col>
                <b-col style="padding-left: 2px">
                  <b-form-input
                    v-model="form.title"
                    placeholder="Titel"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col style="padding-right: 2px">
                  <b-form-input
                    v-model="form.firstName"
                    placeholder="Vorname"
                  ></b-form-input>
                </b-col>
                <b-col style="padding-left: 2px">
                  <b-form-input
                    v-model="form.lastName"
                    :required="true"
                    placeholder="Nachname*"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-input
                    v-model="form.company"
                    placeholder="Unternehmen"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-input
                    v-model="form.email"
                    :required="true"
                    type="email"
                    placeholder="E-Mail-Adresse*"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-input
                    v-model="form.phone"
                    type="tel"
                    placeholder="Telefonnummer"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-textarea
                    v-model="form.message"
                    no-resize
                    rows="4"
                    placeholder="Ihre Nachricht an uns ..."
                  ></b-form-textarea>
                </b-col>
              </b-row>
              <div class="d-flex flex-wrap flex-lg-nowrap mt-1">
                <div class="order-lg-0 order-1">
                  <b-button
                    id="contactBtn"
                    type="submit"
                    variant="primary"
                    class="button px-xl-4 px-sm-1 mt-3 mt-lg-0"
                  >
                    <b-icon icon="envelope" class="mr-1"></b-icon>
                    <span>Absenden</span>
                  </b-button>
                </div>
                <div class="order-lg-1 order-0">
                  <div class="contact-disclaimer ml-lg-3">
                    Die awh GmbH wird alle hier bereitgestellten Informationen
                    ausschließlich in Übereinstimmung mit der
                    <a href="/datenschutz">Datenschutzerklärung</a> verwenden.
                  </div>
                </div>
              </div>
              <div id="hcaptcha-container"></div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contact } from "../config/globals";
export default {
  name: "Contact",
  props: ["person"],
  data: () => {
    return {
      contact: contact.all,
      form: {
        gender: "",
        title: "",
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  mounted() {
    if (this.person) this.contact = contact[this.person];
    this.whenAvailable("hcaptcha", () => {
      hcaptcha.render("hcaptcha-container", {
        size: "invisible",
        sitekey: "60f90d43-c5a7-4735-9719-151decf4bf42",
        callback: this.onSubmit,
      });
    });
  },
  methods: {
    clearForm() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ""));
    },
    validate(event) {
      event.preventDefault();
      hcaptcha.execute();
    },
    onSubmit(token) {
      // Send Mail
      var formData = new FormData();
      Object.keys(this.form).forEach((key) =>
        formData.append(key, this.form[key])
      );
      formData.append("hcaptcha-token", token);
      this.axios
        .post("/_api/contact.php", formData)
        .then(() => {
          this.$bvToast.show("contact-success");
          this.clearForm();
        })
        .catch(() => {
          this.$bvToast.show("contact-error");
        });
    },
    whenAvailable(name, callback) {
      var interval = 10; // ms
      window.setTimeout(() => {
        if (typeof hcaptcha !== "undefined") callback(window[name]);
        else this.whenAvailable(name, callback);
      }, interval);
    },
  },
};
</script>

<style scoped>
.contact-img {
  max-width: 500px;
  position: relative;
  filter: grayscale(100%);
}
.contact-info-area {
  background-color: #f2d3ce;
}
.contact-form-area {
  max-width: 600px;
  background-color: #ebebeb;
}
.contact-data {
  text-align: left;
}
.contact-form {
  text-align: left;
  height: 100%;
}
.full-height {
  height: 100%;
}
.contact-disclaimer {
  font-size: 1rem;
}
.row {
  margin-bottom: 6px;
}
#contact-form {
  height: 85%;
}
#contactBtn {
  min-width: 150px;
}
</style>