const domain = 'awh-beratung.de';

const contact = {
    all: {
        pos: "them",
        image: "/assets/contact/contact-all-min.png",
        name: "Brigitte Wigand-Heppelmann",
        name2: "Hans-Eberhard Heppelmann",
        phone: "+49 (0) 2381 870679",
        mail: "kontakt@" + domain
    },
    heppelmann: {
        pos: "him",
        image: "/assets/contact/contact-heppelmann.png",
        name: "Hans-Eberhard Heppelmann",
        phone: "+49 (0) 2381 870679",
        mail: "heppelmann@" + domain
    },
    wigand: {
        pos: "her",
        image: "/assets/contact/contact-wigand.png",
        name: "Brigitte Wigand-Heppelmann",
        phone: "+49 (0) 2381 870679",
        mail: "wigand@" + domain
    }
};


module.exports = {
    domain: domain,
    mailDomain: domain,
    contact: contact
};