<template>
  <div>
    <h1 class="mt-3 mb-4">Erfahrungs&shy;austausch (ERFA)</h1>
    <div class="text-container mb-5 description">
      <p>
        Wir veranstalten zweimal im Jahr ERFA-Tagungen mit unterschiedlichen
        Themenbereichen.
      </p>
      <p>
        Die Auswahl der Themen soll sicherstellen, dass Ihnen die neuesten
        Marktentwicklungen mitgeteilt werden und die Gruppe daraus Schlüsse
        zieht. Gleichzeitig geben zum Beispiel die vortragenden Juristen Hilfe
        bei der Gestaltung von Arbeitsverträgen. Die Referenten helfen Ihnen,
        spezielle Marketingmaßnahmen für Ihre Apotheken zu entwickeln.
      </p>
      <p>
        Hier einige Fotos von unseren letzten ERFA-Tagungen.
      </p>
    </div>
    <b-carousel
      id="carousel-erfa"
      :interval="4000"
      controls
      indicators
      background="#ababab"
    >
      <b-carousel-slide
        img-src="/assets/erfa/erfa-runde1.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa-runde2.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa-runde3.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa-praesentation.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa2-praesentation2.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa2-runde1.jpg"
      ></b-carousel-slide>
      <b-carousel-slide
        img-src="/assets/erfa/erfa2-praesentation.jpg"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Erfa",
  metaInfo: {
    title: "ERFA",
  },
  meta: [
    {
      name: "description",
      content:
        "Wir veranstalten Tagungen zum Erfahrungsaustausch, um über die neusten Marktentwicklungen informiert zu bleiben und in der Gruppe neue Erkenntnisse zu gewinnen.",
    },
  ],
};
</script>

<style scoped>
#carousel-erfa {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
.description {
  text-align: left;
}
</style>