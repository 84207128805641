<template>
  <div>
    <h1 class="mt-3 mb-4 element-container">
      {{ getSiteByPath($route.path).label.replace("[shy]", "&shy;") }}
    </h1>
    <div class="description">
      <router-view></router-view>
    </div>
    <Contact :person="getSiteByPath($route.path).contact" />
  </div>
</template>

<script>
import Contact from "../components/Contact.vue";
import { getSiteByPath } from "../config/site-structure";
export default {
  name: "Service",
  props: {},
  components: { Contact },
  methods: { getSiteByPath },
  metaInfo() {
    return {
      title: getSiteByPath(this.$route.path).label.replace("[shy]", ""),
    };
  },
};
</script>

<style scoped>
.description {
  text-align: left;
}
</style>